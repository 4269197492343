.searchBian {
    border: 1px solid #334155;
}

.searchWidth {
    width: 94%;
}

.txt-left {
    text-align: left;
}

.imgWH-20 {
    width: 20px;
    height: 20px;
}

.hidden {
    overflow: hidden;
}

.wrap {
    flex-wrap: nowrap;
}

.game-setting .muiltr-5gnc0a-MuiPaper-root-MuiDialog-paper {
    width: 700px;
    height: 500px;
}

.borders{
  /* background-color:#030407;
   */
  background-color: blue;
  width:290px;
  height:3px;
  border-radius:10px;
  animation: process 2500ms;  
  animation-timing-function: ease-in-out;
}

@keyframes process {
  form{
    width: 290px;
  }
  to{
    width: 0px;
  }
}
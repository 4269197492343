.animate-enter-active,
.animate-exit-active,
.animate-enter-done {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1001;
}

.animateWrapper {
  position: relative;
  z-index: 1001;
  overflow: auto;
  /* padding: 3rem; */
  /* max-width: 540px;
  max-height: 80vh; */
  /* background-color: #0F172A; */
  display: flex;
  flex-direction: column;
  /* Optional */
  /* box-shadow: 0 2px 6px #030a19; */
  border-radius: 5px;
}

.animate-enter .animateWrapper {
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  will-change: transform;
  -webkit-transform: translateY(5%);
  transform: translateY(5%);
}

.animate-enter-active .animateWrapper {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
}

.animate-exit .animateWrapper {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.animate-exit-active .animateWrapper {
  opacity: 0;
  -webkit-transform: translateY(5%);
  transform: translateY(5%);
  transition: transform 0.2s, opacity 0.2s;
}

.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background-color: #000;
  will-change: opacity;
}

.animate-enter .modalOverlay {
  opacity: 0;
  transition: opacity 0.2s;
}

.animate-enter-active .modalOverlay {
  opacity: 0.8;
}

.animate-enter-done .modalOverlay {
  opacity: 0.8;
}

.animate-exit .modalOverlay {
  opacity: 0.8;
}

.animate-exit-active .modalOverlay {
  opacity: 0;
  transition: opacity 0.2s;
}

.animate-close-btn {
  position: absolute;
  right: 0.6rem;
  top: 0.4rem;
  cursor: pointer;
}

@font-face {
    font-family: 'krTxt';
    src: url("https://scource-static.funibet.com/fonts/krTxt.ttf");
}

@font-face {
    font-family: 'aeTxt';
    src: url("https://scource-static.funibet.com/fonts/aeTxt.ttc");
}

@font-face {
    font-family: 'inTxt';
    src: url("../../public/assets/fonts/inTxt.ttc");
}

@font-face {
    font-family: 'tmrTxt';
    src: url("../../public/assets/fonts/tmrTxt.ttc");
}

@font-face {
    font-family: 'Furore';
    src: url("../../public/assets/fonts/Furore.otf");
}

@font-face {
    font-family: 'falconpunch';
    src: url("../../public/assets/fonts/falconpunch.ttf");
}

/* @font-face {
    font-family: 'mmTxt';
    src: url("https://scource-static.funibet.com/fonts/mmTxt.ttc");
} */

@font-face {
    font-family: 'mmTxt';
    src: url("../../public/assets/fonts/mmTxt.ttf");
}

@font-face {
    font-family: 'pakTxt';
    src: url("../../public/assets/fonts/pakTxt.ttf");
}

.justify-content-between {
    justify-content: space-between;
}

.color-EEEEEE {
    color: #EEEEEE;
}

.promotion-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.promotion-listPhone {
    display: flex;
    align-items: center;
    justify-content: left;
}

.promotion-item {
    width: 108px;
    border-radius: 5px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    color: #d7dae0;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
}

.bg1Color {
    background-image: url("../../public/assets/images/index/btnDi1.png");
    background-size: 100% 100%;
    filter: brightness(1);
    transition: all 0.3s;
}

.bg1Color:hover {
    color: #ffffff;
    transition: all 0.3s;
    filter: brightness(1.2);
}

.promotion-item-bg2 {
    background-image: url("../../public/assets/images/index/btnDi2.png");
    background-size: 100% 100%;
    filter: brightness(1);
    transition: all 0.3s;
}

.promotion-item-bg2:hover {
    color: #ffffff;
    transition: all 0.3s;
    filter: brightness(1.2);
}

.promotion-item-bg3 {
    background-image: url("../../public/assets/images/index/btnDi3.png");
    background-size: 100% 100%;
    filter: brightness(1);
    transition: all 0.3s;
}

.promotion-item-bg3:hover {
    color: #ffffff;
    transition: all 0.3s;
    filter: brightness(1.2);
}

.promotion-item-bg4 {
    background-image: url("../../public/assets/images/index/btnDi4.png");
    background-size: 100% 100%;
    filter: brightness(1);
    transition: all 0.3s;
}

.promotion-item-bg4:hover {
    color: #ffffff;
    transition: all 0.3s;
    filter: brightness(1.2);
}

.promotion-item-bg5 {
    background-image: url("../../public/assets/images/index/btnDi5.png");
    background-size: 100% 100%;
    filter: brightness(1);
    transition: all 0.3s;
}

.promotion-item-bg5:hover {
    color: #ffffff;
    transition: all 0.3s;
    filter: brightness(1.2);
}


.promotion-item-bg6 {
    background-image: url("../../public/assets/images/index/btnDi6.png");
    background-size: 100% 100%;
    filter: brightness(1);
    transition: all 0.3s;
}

.promotion-item-bg6:hover {
    color: #ffffff;
    transition: all 0.3s;
    filter: brightness(1.2);
}

.promotion-item-img {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -18px;
    right: -14px;
}

.promotion-item-img img {
    width: 100%;
}

.vipDiv {
    position: relative;
    width: 224px;
    height: 44px;
    margin-top: 8px;
    border-radius: 5px;
    overflow: hidden;
}

.vip-item-text {
    position: absolute;
    width: 224px;
    height: 44px;
    line-height: 44px;
    padding-left: 8px;
    font-size: 13px;
    font-weight: 600;
    color: #B9BBC0;
}

.vipDiv:hover .vip-item-text {
    color: #ffffff;
}

.vipDiv:hover .vip-item-img {
    transition: all 0.2s;
    filter: brightness(1.5);
}

.vip-item-img {
    position: absolute;
    width: 224px;
    height: 44px;
    transition: all 0.2s;
    filter: brightness(1);
}

.posZhiZhenAbsolute {
    position: absolute;
    bottom: -18px;
    right: -14px;
}

.turnAni {
    animation: fadenum 3.6s linear infinite;
}

.turnAni2 {
    animation: fadenum 7.2s linear infinite;
}

@keyframes fadenum {
    100% {
        transform: rotate(360deg);
    }
}

.txtBrightness {
    transition: all 0.25s;
    filter: brightness(1);
}

.txtBrightness:hover {
    transition: all 0.25s;
    filter: brightness(1.3);
}

.btnOpacity {
    transition: all 0.25s;
    opacity: 0.7;
}

.btnOpacity:hover {
    transition: all 0.25s;
    opacity: 1;
}


:root {
    --title-family: 'pfht_z';
    --title-family2: 'Furore';
    --title-family3: 'falconpunch';
}

.funbetTxt {
    font-family: var(--title-family) !important;
}

.titleTxt {
    font-family: var(--title-family2) !important;
}

.titleTxt2 {
    font-family: var(--title-family3) !important;
}

.muiltr-y31q5n-MuiTypography-root {
    font-family: var(--title-family);
}

.muiltr-qzfjkd-MuiTypography-root {
    font-family: var(--title-family);
}

.muiltr-ww7b3v-MuiFormLabel-root-MuiInputLabel-root {
    font-family: var(--title-family);
}

.muiltr-9n4h75-MuiButtonBase-root-MuiMenuItem-root {
    font-family: var(--title-family);
}

.muiltr-81s0os-MuiButtonBase-root-MuiTab-root {
    font-family: var(--title-family);
}

.muiltr-1niqtu4-MuiTypography-root {
    font-family: var(--title-family);
}

@media screen and (min-width: 0px) and (max-width: 750px) {
    .promotion-item {
        width: 32%;
        border-radius: 5px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 600;
        color: #B9BBC0;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;
    }
}
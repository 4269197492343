/* .thumbs-wrapper {
    display: none !important;
} */
body {
    -webkit-user-select: none;
}

.mt-4 {
    margin-top: .4rem !important;
}

.mr-4 {
    margin-right: .4rem !important;
}

.ml-4 {
    margin-left: .4rem !important;
}

.mx-4 {
    margin-left: .4rem !important;
    margin-right: .4rem !important;
}

.px-4 {
    padding-left: .4rem !important;
    padding-right: .4rem !important;
}

.emitter {
    position: relative;
}

.emitter div {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 4px;
    background: var(--particle-color);
}

.bounty-num {
    fill: #fff !important;
    text-shadow: 1px 1px 5px rgb(0 0 0 / 50%);
}

.bounty-num svg {
    color: #ffffff !important;
    margin-top: 6px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.container {
    padding: 0;
}

legend {
    width: auto !important;
}

::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
}

.bodyLock {
    height: 100vh;
    overflow-y: hidden;
}

@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar-thumb {
        border: none !important;
        border-radius: 20px !important;
    }
}

::-webkit-scrollbar-track-piece {
    background: none !important;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:vertical {
    margin-right: 10px !important;
    background-color: #686C75 !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:vertical:hover {
    background-color: #aaa !important;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-corner:vertical {
    background-color: #535353 !important;
    border-radius: 16px;
}

.btnRedColor {
    background-color: #C22844;
}

.ps__rail-y {
    width: 0 !important;
}

.width-fit {
    width: fit-content;
}

.ps__thumb-y:hover {
    width: 6px !important;
}

.muiltr-vqtvaj.me .bubble {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #14C2A3;
}

/* 手指鼠标 */
.btnPointer {
    cursor: pointer;
}

.muiltr-vqtvaj.me {
    padding-left: 0px;
}

.chatWidth {
    max-width: 240px;
    min-width: 12px;
    word-break: break-all;
}

.mbFont {
    font-family: 'Inter var,Roboto,"Helvetica",Arial,sans-serif'
}

.borderRadius-10 {
    border-radius: 10px;
}

.borRadius-10 {
    border-radius: 10px;
}

.text-align {
    text-align: center;
}

/* flex內容水平居中，第二条是可以换行 */
.justifyContent {
    justify-content: center;
    flex-wrap: wrap;
}

.text-alignLeft {
    text-align: left;
}


.align-item {
    align-items: center;
}

.borderRadius-8 {
    border-radius: 8px;
}

.promotion-list1 {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    flex-wrap: wrap;
}

.promotion-list2 {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    flex-wrap: wrap;
}

.promotion-list3 {
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    padding-top: 20px;
}

.sportTitleborderRadius-8 {
    border-radius: 8px 8px 0 0;
}

.flexWrap {
    flex-wrap: wrap;
}

.sportTitleborderRadius2-8 {
    border-radius: 0px 0px 8px 8px;
}

.borderRadius-5 {
    border-radius: 5px;
}


.marginLeft230 {
    margin-left: 230px;
}

.marginLeft250 {
    margin-left: 250px;
}

.marginLeft290 {
    margin-left: 290px;
}

.marginTop232 {
    margin-top: 170px;
}

.text-decoration {
    text-decoration: underline
}

.topGameBtnStyleDi {
    width: 240px;
    background-color: #1E293B;
    border-radius: 20px;
}

.topGameBtnStyle {
    border-radius: 20px;
    height: 40px;
    text-align: center;
    line-height: 40px;
}

.zuQiu-img {
    content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu.png");
}

.zuQiu-img1 {
    content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu1.png");
}

.zuQiu-img2 {
    content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu2.png");
}

.zuQiu-img3 {
    content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu3.png");
}

.zuQiu-img4 {
    content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu4.png");
}

.zuQiu {
    width: 100%;
    border-radius: 10px;
}

.height-24 {
    height: 24px;
}

.height-26 {
    height: 26px;
}

.height-28 {
    height: 28px;
}

.height-36 {
    height: 36px;
}

.height-40 {
    height: 40px;
}

.height-42 {
    height: 42px;
}

.height-46 {
    height: 46px;
}

.height-52 {
    height: 52px
}

.height-60 {
    height: 60px;
    line-height: 60px;
}

.height-70 {
    height: 70px;
}

.height-80 {
    height: 80px;
}

.height-90 {
    height: 90px;
}

.height-100 {
    height: 100px
}

.height-130 {
    height: 130px
}

.height-160 {
    height: 160px
}

.height-170 {
    height: 170px
}

.height-215 {
    height: 215px
}

.height-220 {
    height: 220px;
}

.height-406 {
    height: 406px;
}

.height-470-zuQiu {
    height: 470px;
}

.height-504 {
    height: 504px;
}

.height-700 {
    height: 700px;
}

.height-740 {
    height: 740px;
}

.imgWH-25 {
    width: 25px;
    height: 25px;
}

.imgHui {
    opacity: 0.4;
}

.imgWH-30 {
    width: 30px;
    height: 30px;
}

.width-80bai {
    width: 80%;
}

.width-42 {
    width: 42px;
}

.width-80 {
    width: 80px;
}

.width-85 {
    width: 85px;
}

.width-100 {
    width: 100px;
}

.width-102 {
    width: 102px;
}

.width-120 {
    width: 120px;
}

.width-140 {
    width: 140px;
}

.width-160 {
    width: 160px;
}

.width-180 {
    width: 180px;
}

.width-260 {
    width: 320px;
}

.width-280 {
    width: 280px;
}

.width-250 {
    width: 250px;
}

.width-300 {
    width: 300px;
}

.width-344 {
    width: 344px;
}

.width-350 {
    width: 350px;
}

.width-381 {
    width: 381px;
}

.width-400 {
    width: 400px;
}

.width-480 {
    width: 480px;
}

.width-558 {
    width: 558px;
}

.width-580 {
    width: 580px;
}

.width-806 {
    width: 806px;
}

.widthPhone-164 {
    width: 164px;
}

.recommendWidth {
    max-width: 1196px;
    min-width: 100px;
}

.myBetBtnColor {
    background-color: #242A38;
}

.walletDi {
    background-color: #1E293B;
    border-radius: 20px;
}

.sportMainKuangColor {
    background-color: #1E293B;
}

.phoneComTop {
    width: auto;
}

.btnYellowColor {
    background-color: #FBD608;
}

.txtColorHe {
    color: #320711;
}

.txtColorHui {
    color: #9CA7BE;
}

.walletTxt {
    height: 40px;
    line-height: 40px;
}

.walletBtn {
    width: 88px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
}

.betDiColor {
    background-color: #111827;
}

.VipBgDiColor {
    background-color: #080808;
}

.walletBtnColor {
    background-color: #0C8B7F;
}

.walletBtnColor:hover {
    background-color: #0A796F;
}

.copyBg {
    background-image: none;
}

.copyBg:hover {
    background-image: url("../../public/assets/images/index/copyBtn1.png");
}

.walletBtn-txt1 {
    font-size: 14px;
    line-height: 14px;
}

.copyTxt {
    height: 40px;
    line-height: 40px;
}

.sigin-txt {
    font-size: 32px;
}

.sigin-txt1 {
    font-size: 44px;
}

.siginTxt {
    margin-top: 2.4rem;
}

.sigin-txt-yellow {
    color: #FFCC00 !important;
}

.directionBtn {
    width: 36px;
    height: 36px;
    background-color: #1E293B;
}

.mt-64 {
    margin-top: 64px;
}

.mt-34 {
    margin-top: 34px;
}

.activitybanner {
    width: 80%;
    min-width: 1196px;
}

.bannerbutton {
    width: 289px;
    height: 164px;
    font-size: 18px;
    transition: all 0.2s;
    filter: brightness(1);
}

.bannerbutton:hover {
    transition: all 0.2s;
    filter: brightness(1.25);
}

.filterbrightness {
    filter: brightness(1.5);
    transition: all 0.2s;
}

.banner-txt {
    width: 250px;
    text-align: left;
}

.blueColor {
    color: #6C9CFF
}

.ziColor {
    color: #B83CFF
}

.yellowColor {
    color: #FF9000
}

.greenColor {
    color: #00FF7E
}

.ml-123 {
    margin-left: 123px;
}

.mr-123 {
    margin-right: 123px;
}

.ml-160 {
    margin-left: 10%;
}

.font-30 {
    font-size: 30px !important;
}

.font-28 {
    font-size: 28px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-22 {
    font-size: 22px !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-16 {
    font-size: 16px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-14 {
    font-size: 14px !important;
}

.font-12 {
    font-size: 12px !important;
}

.navbarFontSize {
    position: relative;
    z-Index: "auto";
}

.navbarImgZindex {
    z-Index: "0";
}

.fontColor {
    color: #B8BABF;
}

.testRed {
    border: 1px solid red;
}

.marginJuZhong {
    margin: 0px auto;
}

.lineHeght-24 {
    line-height: 24px;
}

.lineHeght-26 {
    line-height: 26px;
}

.borderKuang {
    border: 2px solid #14C2A3;
}

.crypto-txt {
    width: 564px;
    height: 60px;
    font-size: 26px;
    line-height: 37px;
    overflow: hidden;
}

.crypto {
    width: 100%;
}

.ctyptoBtn-txt {
    text-align: center;
    line-height: 60px;
}

.lineHeght-18 {
    line-height: 18px;
}

.backGround-index {
    background-color: #0E1421;
}

.backGroundGreenBtn {
    background-color: #14C2A3;
    transition: all 0.2s;
}

.backGroundGreenBtn:hover {
    background-color: #15A58B;
    transition: all 0.2s;
}


.backGroundZiBtn {
    background-color: #8234F7;
    transition: all 0.2s;
}

.backGroundZiBtn:hover {
    background-color: #722ed8;
    transition: all 0.2s;
}

.phoneChatPl {
    padding-left: 8px;
}


.shareSelectGroundDiColor {
    color: #212D36;
}

.chaCloseBtn {
    transform: rotate(0deg);
    transition: all 0.2s;
}

.rotate22-5 {
    transform: rotate(22.5deg);
}

.rotate30 {
    transform: rotate(30deg);
}

.rotate45 {
    transform: rotate(45deg);
}

.rotate60 {
    transform: rotate(60deg);
}

.rotate67-5 {
    transform: rotate(67.5deg);
}

.rotate90 {
    transform: rotate(90deg);
}

.rotate112-5 {
    transform: rotate(112.5deg);
}

.rotate120 {
    transform: rotate(120deg);
}

.rotate135 {
    transform: rotate(135deg);
}

.rotate150 {
    transform: rotate(150deg);
}

.rotate157-5 {
    transform: rotate(157.5deg);
}

.rotate180 {
    transform: rotate(180deg);
}

.rotate202-5 {
    transform: rotate(202.5deg);
}

.rotate210 {
    transform: rotate(210deg);
}

.rotate225 {
    transform: rotate(225deg);
}

.rotate240 {
    transform: rotate(240deg);
}

.rotate247-5 {
    transform: rotate(247.5deg);
}

.rotate270 {
    transform: rotate(270deg);
}

.rotate292-5 {
    transform: rotate(292.5deg);
}

.rotate300 {
    transform: rotate(300deg);
}

.rotate315 {
    transform: rotate(315deg);
}

.rotate337-5 {
    transform: rotate(337.5deg);
}

.rotate360 {
    transform: rotate(360deg);
}

.rotate330 {
    transform: rotate(330deg);
}

.chaCloseBtn:hover {
    transform: rotate(90deg);
    transition: all 0.2s;
}

.txtBlackColor {
    color: #0E1421;
}

.backGround-foot {
    background-color: #1E2633;
}

.backGround-footImg {
    background-image: url("../../public/assets/images/index/footDi.png");
    background-repeat: no-repeat;
}

.borderRadius {
    border-radius: 9999px;
}

.notice-num {
    padding: 0 7px;
    font-size: 11px;
    font-weight: 600;
    height: 20px;
    min-width: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    background-color: #14C2A3;
    color: rgb(255, 255, 255);
}

.font-weight500 {
    font-weight: 500;
}

.fontBold {
    font-weight: bold;
}

.font-weight800 {
    font-weight: 800;
}

.line-height-40 {
    line-height: 40px;
}

.gamesListBtn {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    float: left;
}

.flex-wrap {
    flex-wrap: wrap;
}

.textAlignRight {
    text-align: right;
}

.Zindex {
    z-index: 99;
}

.ZindexMax {
    z-index: 999;
}

.gamesListBtnBackground-Color {
    background-color: #334155;
}

.gamesList-Game {
    width: 19%;
    text-align: center;
    border: 1px solid red;
}

.gamesList-User {
    width: 19%;
    text-align: center;
    border: 1px solid red;
}

.gamesList-Time {
    width: 10%;
    text-align: center;
    border: 1px solid red;
}

.gamesList-Bet {
    width: 20%;
    text-align: center;
    border: 1px solid red;
}

.gamesList-Multiplier {
    width: 8%;
    text-align: center;
    border: 1px solid red;
}

.gamesList-Payout {
    width: 20%;
    text-align: center;
    border: 1px solid red;
}

.backGroundColorNode {
    background-color: #0E1421;
}

.sportTitlebackGroundColor {
    background-color: #161D2A;
}

.mt-280 {
    margin-top: 280px;
}

.colorGameListTitle {
    color: #B9BBC0;
}

.viptxtColorHui {
    color: #6e6e6e;
}

.footBtnSty {
    width: 180px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background-color: #1E2633;
    border: 2px solid #14C2A3;
}

.chatMr-6 {
    margin-right: 6px;
}

.foot1 {
    width: 325px;
}

.foot2 {
    width: 200px;
}

.imgHidden {
    overflow: hidden;
    position: relative;
    transition: all 0.2s;
    transform: scale(1);
}

.displayNone {
    display: none;
}

.displayBlock {
    display: block;
}

.displayPhoneSwitch {
    display: none;
}

.displayPhoneSwitch1 {
    display: block;
}

.displayPhoneSwitch2 {
    display: flex;
}

.displayPhoneSwitch3 {
    display: none;
}



.maxWidth {
    width: 153px;
    height: 153px;
}

.imgGameWh {
    width: 153px;
}

.game-other-game-tag {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    background: rgba(0, 0, 0, .6);
    display: none;
}

.game-other-game-list:hover .game-other-game-tag {
    display: flex;
}

.imgGameWh .displayImg {
    position: absolute;
    display: none;
}

.jiaoBiaoImg {
    position: absolute;
}

.imgGameWh:hover .displayImg {
    position: absolute;
    display: block;
}

.overflowHidden {
    overflow: hidden;
}


.imgHidden:hover .imgMohu {
    position: absolute;
    transition: all 0.2s;
    transform: scale(1.1);
}


.imgMohu {
    position: absolute;
    transition: all 0.2s;
    transform: scale(1);
}

.imgGameWh:hover .imgMohu {
    position: absolute;
    transition: all 0.2s;
    transform: scale(1.1);
}

.textDecoration {
    text-decoration: none;
}

.positionAb {
    position: absolute;
}

.providerImg {
    position: relative;
    width: 153px;
    height: 60px;
}

.yuanTips {
    top: -7px;
    left: 20px
}

.circle {
    background: #334155;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.txtFootColor {
    transition: all 0.25s;
    color: #ffffff;
}

.txtColor {
    color: #ffffff;
}

.txtFootColor:hover {
    transition: all 0.25s;
    color: #16C2A3;
}

.bannerImg1 {
    background-image: url("../../public/assets/images/banner/1.png");
}

.bannerImg2 {
    background-image: url("../../public/assets/images/banner/2.png");
}

.bannerImg3 {
    background-image: url("../../public/assets/images/banner/3.png");
}

.bannerImg4 {
    background-image: url("../../public/assets/images/banner/4.png");
}

.width30Bai {
    width: 30%;
}

.txtGreenColor {
    color: #14C2A3;
}

.txtRedColor {
    color: #FF1663;
}

.btnGreenColor {
    background-color: #0C8B7F;
}

.selectBtnColor {
    background-color: #1E2633;
    transition: all 0.25s;
}

.selectBtnColor:hover {
    background-color: #2a3547;
    transition: all 0.25s;
}

.btnGreenColor1 {
    background-color: #14C6A6;
}

.btnGreenColor1:hover {
    background-color: #15A58B;
    transition: all 0.25s;
}

.sportBannerBg {
    background-image: url("https://scource-static.funibet.com/funibet/images/sport/zuQiu.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.affiliateBg {
    background-image: url("https://scource-static.funibet.com/funibet/images/affiliate/xcDi.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
}

.affiliateBg2 {
    background-image: url("https://scource-static.funibet.com/funibet/images/affiliate/di1.png");
    background-repeat: no-repeat;
}

.fundBgDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/bigXcDi.png");
    padding: 56px 56px 0px 56px;
    width: 100%;
    height: 466px;
}

.vipBgDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/vipDi.png");
    background-repeat: no-repeat;
    padding: 32px 56px 0px 56px;
    height: 504px;
}

.affiliateBg3 {
    background-image: url("https://scource-static.funibet.com/funibet/images/affiliate/labaDi.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 20px;
    height: 170px;
}

.checkInPcDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/pcQianDao.png");
    background-repeat: no-repeat;
}

.checkInDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/checkInDi.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 747px;
    height: 439px;
}

.checkInTitleMt {
    padding-top: 8px;
    font-size: 28px;
}

.closeBtnCheckIn {
    margin-top: 5rem;
}

.pcMgH {
    padding: 24px 0px 24px 0px;
}

.checkInTxtmtMiaoShu {
    font-size: 16px;
    margin-top: 48px;
    font-weight: 500;
    color: #AF57E8;
}

.vipLvDiImg {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/vipLvDi.png");
    width: 373px;
    height: 424px;
    padding: 0px 20px;
    margin-right: 36px;
}

.vipLvDiImg2 {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/vipDi2.png");
    width: 1196px;
    height: 556px;
    background-repeat: no-repeat;
    padding-top: 48px;
}

.depositDi {
    width: 360px;
    height: 460px;
}

.depositDiImg {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/czlb1.png");
}

.scMoneyDi1 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDi1.png");
    width: 360px;
    height: 460px;
}

.scMoneyTitleDi1 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/jjjDiTitle.png");
    width: 310px;
    height: 67px;
    font-size: 26px;
    text-align: center;
    color: #FEC442;
    padding-top: 6px;
}

.scMoneyDi2 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDi2.png");
    width: 360px;
    height: 460px;
}

.scMoneyTitleDi2 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDiTitle2.png");
    width: 310px;
    height: 67px;
    font-size: 26px;
    text-align: center;
    color: #FEC442;
    padding-top: 6px;
}


.scMoneyDi3 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDi3.png");
    width: 360px;
    height: 460px;
}

.scMoneyTitleDi3 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDiTitle3.png");
    width: 310px;
    height: 67px;
    font-size: 26px;
    text-align: center;
    color: #FEC442;
    padding-top: 6px;
}

.suffTitle8 {
    width: 340px;
    margin: 20px 0px 10px 10px;
}

.titleDeposit {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDiTitle.png");
    width: 310px;
    height: 67px;
    font-size: 26px;
    text-align: center;
    color: #FEC442;
    padding-top: 6px;
}

.titleCzDeposit {
    width: 310px;
    height: 67px;
    font-size: 26px;
    text-align: center;
    color: #FEC442;
    padding-top: 6px;
}

.titleCzDepositBg1 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/jjjDiTitle.png");
}

.titleCzDepositBg2 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDiTitle2.png");
}

.titleCzDepositBg3 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/sclbDiTitle3.png");
}

.bigXcDeposit {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/depxc.png");
    width: 216px;
    height: 348px;
}

.bigXcDeposit2 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/depxc2.png");
    width: 216px;
    height: 348px;
}

.bigXcBindImg {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/phoneXc.png");
    width: 282px;
    height: 330px;
}


.bigXcWeiHuImg {
    background-image: url("../../public/assets/images/nav/weiHu.png");
    width: 282px;
    height: 330px;
}

.bigXcDepositTitle {
    font-size: 36px;
    color: #ffc600;
    text-align: right;
}

.bigXcDepositTitle2 {
    font-size: 22px;
    color: #FFFFFF;
    text-align: right;
}

.bigXcDepositTitle3 {
    font-size: 24px;
    color: #ffc600;
    text-align: left;
}

.bigXcDepositBan {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/czlb3.png");
    width: 293px;
    height: 62px;
    transform: rotate(-5deg);
}

.depositBanTxt {
    font-size: 17px;
    color: #333333;
    height: 20px;
    width: 76px;
    overflow: hidden;
}

.reliefDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/jjjDi.png");
    max-width: 360px;
    width: 100%;
    height: 556px;
}

.btnShareTitle {
    font-size: 26px;
    width: 70px;
    text-align: center;
    color: #ffc600;
}

.titleRelief {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/jjjDiTitle.png");
    width: 310px;
    height: 67px;
    font-size: 30px;
    text-align: center;
    color: #FEC442;
}

.bannerRelief {
    font-size: 18px;
    color: #ffc600;
    width: 100%;
    height: 48px;
    overflow: hidden;
    /* 影子 */
    text-shadow: 1px 2px 2px #292929;
}

.textShadow {
    text-shadow: 1px 2px 2px #292929;
}

.bigXcRelief {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/jjjxc.png");
    width: 274px;
    height: 286px;
    margin: 0px auto;
}

.bigXcReliefTitle {
    text-align: center;
    width: 160px;
    font-size: 18px;
    color: #ffc600;
    text-shadow: 1px 2px 2px #292929;
}

.closeBtnRelief {
    margin-top: 37px;
    z-index: 999;
}

.faGuang {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/faGuan.png");
    width: 392px;
    height: 392px;
}


.spinDi {
    background-image: url("../../public/assets/images/nav/diPan3.jpg");
    max-width: 400px;
    width: 100%;
    height: 668px;
    border-radius: 15px;
}

.spinbtn1textColor {
    color: #653120;
    font-size: 18px;
    font-weight: bold;
}

.spinbtn1Color {
    background-color: #653120;
}

.spinbtn2Color {
    background-color: #A03426;
}

.spinbtn3Color {
    background-color: #9856F5;
}

.vipZiRota {
    transform: rotate(-45deg);
}

.closeBtnspin {
    margin-top: 0px;
    z-index: 999;
}

.spinDi1 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/diPan1.png");
    background-repeat: no-repeat;
    width: 308px;
    height: 308px;
    max-width: 308px;
    max-height: 308px;
}

.spinDi2 {
    width: 354px;
    height: 352px;
    max-width: 354px;
    max-height: 352px;
}

.spinDi3 {
    width: 99px;
    height: 102px;
}

.spinDi4 {
    width: 360px;
    height: 332px;
    max-width: 360px;
    max-height: 332px;
}

.spinDi10 {
    width: 98%;
    height: 84px;
    margin: 0px auto;
}

.spinDi7 {
    width: 360px;
    height: 106px;
}

.spinDi8 {
    max-width: 400px;
    width: 400px;
    height: 390px;
    margin-top: 20px;
}

.spin-bottom {
    height: 84px;
    box-sizing: border-box;
    background: #151617;
    border-radius: 8px;
}

.spin-bonus {
    width: 49.5%;
    height: 100%;
    background: #191A1B;
    border-radius: 8px;
    color: #6C7075;
    font-size: 14px;
    box-sizing: border-box;
}

.spin-record {
    overflow: hidden;
}

.spin-vip {
    width: 98%;
    height: 86px;
    padding: 4px 4px 4px 4px;
}

.spin-vip-img {
    width: 44px;
}

.spin-vip-text {
    font-size: 14px;
}

.spin-vip-2 {
    width: 100%;
    height: 22px;
    font-size: 14px;
    margin-top: 15px;
    line-height: 22px;
}

.spin-vip-3 {
    font-size: 14px;
    margin-top: 6px;
}

#spin {
    transition: all 4s;
}

.sanImg {
    animation: gradient 1.3s linear infinite;
}

.sanImg1 {
    animation: gradient1 1.3s linear infinite;
}

.index4bigImg {
    animation: indexImgTouMing2 0.2s linear;
    animation-fill-mode: forwards;
}

.index4bigImg:hover {
    animation: indexImgTouMing 0.2s linear;
    animation-fill-mode: forwards;
}

.spinZhuanBtnImg {
    animation: suoFang 2s ease-in-out infinite;
    opacity: 0;
}

.spinZhuanBtnImgX {
    animation: suoFang 2s ease-in-out infinite;
    animation-delay: 0.3s;
    opacity: 0;
}

.spinZhuanReBtn {
    animation: zpFangDa 0.4s cubic-bezier(.62, -0.14, .62, 1.25);
    animation-fill-mode: forwards;
}

@keyframes suoFang {
    0% {
        opacity: 1;
        transform: scale(0.95) rotate(0deg);
    }

    50% {
        opacity: 1;
        transform: scale(1.05) rotate(5deg);
    }

    100% {
        opacity: 1;
        transform: scale(0.95) rotate(0deg);
    }
}

.spinZhuanBtnImg2 {
    animation: suoFang2 2s ease-in-out infinite;
}

@keyframes suoFang2 {
    0% {
        transform: scale(1.05) rotate(5deg);
    }

    50% {
        transform: scale(0.95) rotate(0deg);
    }

    100% {
        transform: scale(1.05) rotate(5deg);
    }
}

@keyframes zpFangDa {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

@keyframes indexImgTouMing {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes indexImgTouMing2 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes gradient {
    0% {
        opacity: 0;
    }

    6% {
        opacity: 0.125;
    }

    12% {
        opacity: 0.25;
    }

    18% {
        opacity: 0.375;
    }

    25% {
        opacity: 0.5;
    }

    31% {
        opacity: 0.625;
    }

    37% {
        opacity: 0.75;
    }

    43% {
        opacity: 0.875;
    }

    50% {
        opacity: 1;
    }

    56% {
        opacity: 0.875;
    }

    62% {
        opacity: 0.75;
    }

    68% {
        opacity: 0.625;
    }

    75% {
        opacity: 0.5;
    }

    81% {
        opacity: 0.375;
    }

    87% {
        opacity: 0.25;
    }

    93% {
        opacity: 0.125;
    }

    100% {
        opacity: 0;
    }
}

@keyframes gradient1 {

    0% {
        opacity: 1;
    }

    6% {
        opacity: 0.875;
    }

    12% {
        opacity: 0.75;
    }

    18% {
        opacity: 0.625;
    }

    25% {
        opacity: 0.5;
    }

    31% {
        opacity: 0.375;
    }

    37% {
        opacity: 0.25;
    }

    43% {
        opacity: 0.125;
    }

    50% {
        opacity: 0;
    }

    56% {
        opacity: 0.125;
    }

    62% {
        opacity: 0.25;
    }

    68% {
        opacity: 0.375;
    }

    75% {
        opacity: 0.5;
    }

    81% {
        opacity: 0.625;
    }

    87% {
        opacity: 0.75;
    }

    93% {
        opacity: 0.875;
    }

    100% {
        opacity: 1;
    }
}

.spinIconShadow {
    box-shadow: 2px 2px 2px #202020;
}

.spinDi5 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/san2.png");
    width: 472px;
    height: 500px;
}

.spinDi6 {
    background-image: url("https://scource-static.funibet.com/funibet/images/nav/san2.png");
    width: 472px;
    height: 500px;
}

.phoneDi {
    max-width: 637px;
    width: 637px;
    height: 505px;
}

.phoneDiWH .muiltr-5gnc0a-MuiPaper-root-MuiDialog-paper {
    max-width: 637px;
    height: 505px;
}

.shareDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/shareDi.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    max-width: 472px;
    height: 314px;
}

.btnShare1 {
    top: 58px;
    right: 0px;
    bottom: 0px;
    left: 128px;
}

.btnShare2 {
    top: 150px;
    right: 0px;
    bottom: 0px;
    left: 200px;
}

.btnShare3 {
    top: 58px;
    right: 0px;
    bottom: 0px;
    left: 287px;
}

.btnShare4 {
    top: 150px;
    right: 0px;
    bottom: 0px;
    left: 368px;
}

.btnShare5 {
    top: 278px;
    right: 0px;
    bottom: 0px;
    left: 110px;
    color: #ffffff;
    font-size: 16px;
}

.huoDongDing {
    padding: 10px 32px 32px 32px;
}

.affHuoDongDing {
    margin-top: 26px;
    padding-top: 0px;
}

.btnShare1Img {
    width: 70px;
    height: 70px;
}

.closeBtnShare {
    margin-top: 0px;
    z-index: 999;
}

.shareBannerTitle {
    font-size: 26px;
}

.redPointSty {
    left: 50px;
    top: 40px;
}

.receivedSty {
    left: 44px;
    top: 40px;
}

.giftDi {
    background-image: url("../../public/assets/images/nav/diPan4.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 534px;
    height: 352px;
}


.suggDi {
    background-image: url("../../public/assets/images/index/jianYiDi.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 360px;
    height: 600px;
}

.closeBtnSuff {
    margin-top: 16px;
}

.giftTitleMt {
    padding-top: 14px;
    font-size: 32px;
}

.suffTitleMt {
    padding-top: 0px;
    font-size: 19px;
}

.suffTitle1 {
    width: 310px;
    font-size: 14px;
    color: #ffffff;
}

.suffTitle7 {
    font-size: 14px;
    width: 310px;
    color: #ffffff;
}

.suffTitle2 {
    font-size: 18px;
    margin-left: 10px;
    margin-top: 14px;
    color: #ffffff;
}

.suffTitle3 {
    margin-left: 139px;
    margin-top: 19px;
    color: #ffffff;
}

.suffBoxTextField {
    width: 340px;
    margin-left: 10px;
    margin-top: 12px;
}

.suffBox .muiltr-l4h9aw-MuiInputBase-root-MuiFilledInput-root {
    background-color: rgba(5, 48, 53, 1);
    padding: 12px;
}

.giftBtnmt-48 {
    margin-top: 48px;
}

.giftDiBtn {
    width: 160px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
}

.closeBtnGift {
    margin-top: 6rem;
}

.giftTxtmt-32 {
    font-size: 28px;
    margin-top: 32px;
}

.giftTxtmtInput {
    margin-top: 32px;
}

.phoneMove3 {
    bottom: 2%;
    animation: testMove3 0.4s ease-in-out;
    animation-fill-mode: forwards;
}

.phoneMove4 {
    top: 0%;
    animation: testMove4 0.4s ease-in-out;
    animation-fill-mode: forwards;
}

.phoneMove5 {
    right: 0%;
    animation: testMove5 0.4s ease-in-out;
    animation-fill-mode: forwards;
}

.phoneMove6 {
    bottom: 0%;
    animation: testMove6 0.4s ease-in-out;
    animation-fill-mode: forwards;
}

.phoneGameListMg {
    margin: 8px;
}

@keyframes testMove3 {
    from {
        bottom: 2%;
    }

    to {
        bottom: -14%;
    }
}

@keyframes testMove4 {
    from {
        top: 0%;
    }

    to {
        top: -150px;
    }
}

@keyframes testMove5 {
    from {
        right: 0%;
    }

    to {
        right: -500px;
    }
}

@keyframes testMove6 {
    from {
        bottom: 0%;
    }

    to {
        bottom: -420px;
    }
}

.betDi {
    background-image: url("../../public/assets/images/index/betDi.png");
    width: 279px;
    height: 146px;
}

.backGroundColorYellow {
    background-color: #FAD600;
}

.txtAffiliateBtnColor {
    color: #1C2532;
}

.txtAffiliateBtnColor2 {
    color: #636a73;
}

.affiliateBackGroundColor {
    background-color: #004044;
}

.affiliateLaba {
    margin-left: 200px;
    padding-top: 20px;
    font-size: 16px;
    text-align: 16px;
}

.vipJdTiao {
    height: 20px;
    margin-top: 20px;
}

.vipJdTiao1 {
    margin-top: 20px;
}

.vipJdTiao2 {
    margin-top: 19px;
}

.sportTab .muiltr-8yp4z6-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #16C2A3;
}

.sportTab .muiltr-3ueg8u-MuiTabs-indicator {
    background-color: #16C2A3;
}

/* .RightLayout1 .muiltr-w2sqq1 {
    width: 360px;
    max-width: 360px;
    min-width: 360px;
}

.RightLayout1 .muiltr-w2sqq1>.panel {
    margin-left: 290px;
} */

.RightLayout1Chat {
    width: 66px;
    min-width: 66px;
}

.giftStyle {
    color: #B9BBC0;
}

.giftStyle:hover {
    color: #ffffff;
    background: #1D2432;
}

.signBtnStyle {
    padding: 24px 0px;
    margin-top: 10px;
}

.payDi {
    background-image: url("https://scource-static.funibet.com/funibet/images/index/payDi.png");
    background-size: 100% 100%;
}

.payImg {
    content: url("https://scource-static.funibet.com/funibet/images/index/pay.png");
}

.payPT {
    padding-top: 40px;
}

.phoneTop {
    width: 180px;
    margin-top: 38px;
    font-size: 18px;
    padding: 22px 0px 26px 0px;
}


.buyCryP-20 {
    padding: 20px 20px 20px 20px;
}

.zuQiu .muiltr-m96d3a-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12px 14px;
}

.marginXY {
    margin: 32px 8px 0px 9px;
}

.phoneMx-16 {
    margin: 0px 16px;
}

.phoneMW-224 {
    width: 224px;
}

.phoneMW-222 {
    width: 224px;
}

.workSty {
    min-width: 150px;
    height: auto;
    margin: 0px 9px 0px 10px;
}

.sportPhoneWidth {
    width: auto;
}

.FundText-28 {
    font-size: 28px;
}

.FundWidth-480 {
    width: 480px;
}

.fundBuyBtn {
    margin-top: 84px;
    font-size: 28px;
    height: 60px;
    line-height: 60px;
    width: 180px;
}

.fundTitle0 {
    margin-top: 32px;
}

.fundTitle1 {
    padding-top: 28px;
}

.fundTitle2 {
    width: 25%;
    text-align: center;
}

.fundTitle3 {
    width: 25%;
    text-align: center;
}

.fundTitle4 {
    width: 25%;
    text-align: center;
    margin-right: 32px;
}


.fundLineBtn {
    height: 70px;
    margin-top: 16px;
    padding: 0px 50px;
    margin-left: 48px;
    width: 92%;
}

.Fundwidth-100Icon {
    width: 100px;
}

.Fundml-20 {
    margin-left: 20px;
}

.Fundwh42 {
    width: 42px;
    height: 42px;
    display: inherit;
    margin-top: 4px;
}

.fundTxtIcon {
    font-size: 14px;
    width: 100px;
    text-align: center;
}

.Fundwidth-120Icon {
    width: 120px;
    height: 70px;
    line-height: 70px;
    margin-left: 370px;
}

.fundBtnRev {
    width: 160px;
    height: 70px;
    line-height: 70px;
    margin-left: 250px;
    font-size: 24px;
}

.fundBtnRev1 {
    width: 140px;
    height: 46px;
    line-height: 46px;
    font-size: 22px;
    margin: auto 0;
    margin-left: 260px;
}

.fundJia {
    width: 24px;
    height: 24px;
    margin: 24px 0px 0px 20px;
}

.vipZi {
    font-size: 72px;
    margin-left: 400px;
}

.vipZi2 {
    font-size: 52px;
    margin-left: 400px;
}

.vipZi3 {
    width: 806px;
    height: 130px;
    padding: 12px 64px 0px 64px;
}


.vipZi4 {
    width: 480px;
    height: 26px;
    font-size: 16px;
    margin-top: 36px;
    line-height: 26px;
}

.vipwidth-80 {
    width: 80px;
}

.viptext-20 {
    font-size: 20px;
}

.vipZi5 {
    font-size: 16px;
    margin-top: 60px;
}

.vipZi6 {
    font-size: 36px;
    margin-top: 36px;
}

.vipZi7 {
    margin-top: 20px;
    font-size: 24px;
    padding: 0px 20px;
}

.vipZi8 {
    font-size: 36px;
    margin-left: 100px;
}

.vipZi9 {
    font-size: 20px;
    margin-left: 240px;
    width: 74%;
}

.vipZi10 {
    font-size: 24px;
    margin-top: 8px;
    margin-left: 240px;
    width: 74%;
}

.vipZi11 {
    font-size: 20px;
    line-height: 16px;
    margin-top: 8px;
    margin-left: 240px;
    width: 74%;
}

.vipZi12 {
    margin-left: 12px;
}

.vipZi13 {
    font-size: 28px;
    margin-top: 20px;
    margin-left: 550px;
    width: 180px;
    padding: 8px 0px;
}

.vipJdWidth {
    width: 480px;
}



.signUp {
    background: #111827;
    transition: all 0.2s;
}


.chatOverHidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.signUp:hover {
    background: #1E2633;
    transition: all 0.2s;
}

.walletMl {
    margin-left: 0px;
    margin-right: 0px;
}

.copyMl {
    margin-left: 0px;
    margin-right: 0px;
}

.mr290 {
    margin-right: 290px;
}

.guoDu {
    transition: all 0.4s;
}

.loginInBtn {
    font-size: 18px;
}

.widthPhone400 {
    width: 400px;
}

.smallGuoJiaBtn {
    height: 32px;
    width: 32px;
    min-width: 32px;
    min-height: 32px;
    max-height: 32px;
}

.muiltr-vc9ad7-MuiButtonBase-root-MuiButton-root:after {
    width: 0px;
}

.tongZhiBtn2 {
    font-size: 14px;
    color: #B9BBC0;
}

.tongZhiBtn2:hover {
    font-size: 14px;
    color: #ffffff;
}

.affiliateMg {
    margin-top: 20px;
    margin-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 344px;
    height: 406px;
}

.phoneTxtAff {
    font-size: 28px;
}

.phoneTxtAff2 {
    padding-top: 12px;
    font-size: 16px;
}

.phoneTxtAff3 {
    margin-top: 20px;
    margin-bottom: 8px;
    margin-left: 44px;
    font-size: 16px;
}

.phoneTxtAff4 {
    height: 40px;
    line-height: 40px;
}

.phoneTxtAff5 {
    margin-top: 12px;
}

.affilamt-40 {
    margin-top: 40px;
}

.phoneTxtAff6 {
    height: 70px;
}

.phoneTxtAff7 {
    padding: 20px 0px 20px 0px;
    width: 580px;
    border: 2px solid #14C2A3;
}

.phoneTxtAff8 {
    padding: 12px 0px 0px 0px;
    width: 480px;
    height: 100px;
}

.phoneTxtAff9 {
    margin-top: 20px;
}

.phoneTxtAff10 {
    margin-top: 16px;
}

.phoneAffmt-8 {
    margin-top: 8px;
}

.phoneTxtAff99 {
    width: 180px;
    margin-top: 24px;
    font-size: 18px;
    margin-bottom: 0px;
}

.phoneTxtAff12 {
    width: 100%;
}

.phoneTxtAff13 {
    width: 480px;
    height: 60px;
}

.phoneAffmt-16 {
    margin-top: 16px;
    font-size: 24px;
}

.phoneTxtAff14 {
    margin-top: 40px;
    height: 170px;
}

.phoneTxtAff15 {
    padding-top: 20px;
    font-size: 28px;
}

.phoneTxtmx-120 {
    margin: 0px 120px;
}

.phoneTxtAff16 {
    margin-right: 20px;
    margin-top: 32px;
    height: 52px;
}

.phoneTxtAff17 {
    width: 300px;
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 8px;
}

.phoneTxtAff18 {
    width: 300px;
    font-size: 28px;
    padding-top: 6px;
}

.headDiWh {
    width: 300px;
    height: 510px;
}

.headIconWh {
    width: 60px;
    height: 60px;
    border-radius: 999px;
    margin-top: 18px;
}

.userNameID {
    margin-top: 18px;
    margin-left: 16px;
    padding-top: 4px;
}

.iconYuan {
    width: 56px;
    height: 56px;
    border-radius: 999px;
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;
}

.bdBian {
    border: 2px solid #14C2A3;
}

.cookiesSty {
    width: 800px;
    height: 116px;
    background-color: #1E2633;
    border-radius: 10px;
    box-shadow: 3px 3px 3px #000000;
}

.gameTipsSty {
    width: 360px;
    background-color: #1E2633;

    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .6);
}

.subscriptionSty {
    width: 420px;
    height: 134px;
    background-color: #1E2633;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .6);
}

.downAppSty {
    width: 300px;
    height: 370px;
    border-radius: 10;
    background-color: #1E2633;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .5);
    border-radius: 10px;
}

.downAppSty2 {
    position: fixed;
    bottom: 3.4%;
    right: 5.2%;
    z-index: 9999;
}

.activePopupDi {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.activePx {
    padding: 20px;
}

.activeImageDi {
    width: 146px;
    height: 146px;
    margin-left: 20px;
}

.activePopupTxt1 {
    font-size: 20px;
}

.activePopupSty {
    width: 462px;
    height: 218px;
    background-color: #1E2633;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, .6);
    position: fixed;
    bottom: 36%;
    right: 0%;
}

.sportTipsSty {
    position: fixed;
    bottom: 31%;
    margin: 0px auto;
    left: 0%;
    right: 0%;
    z-index: 999;
}

.activeBtn {
    width: 170px;
    overflow: hidden;
    margin-top: 20px;
}

.activeTxt2 {
    width: 260px;
    height: 90px;
    overflow: hidden;
}

.activeTxt3 {
    width: 260px;
    margin-right: 20px;
}

.subscriptionTxt1 {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    width: 290px;
    height: 48px;
    overflow: hidden;
}

.bannerHeightBtn {
    height: 300px;
}

.subscriptionTxt2 {
    font-size: 12px;
    color: #ffffff;
}

.cookies-list1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.subscriptionMg2 {
    margin-left: 26px;
    width: 360px;
}

.cookiesTxt1 {
    width: 80%;
}

.gameTipsTxt1 {
    width: 91.6%;
}

.cookiesBtn {
    margin-top: 20px;
}

.gameMt1 {
    margin-top: 40px;
}

.gameMt2 {
    padding-top: 16px;
    padding-bottom: 16px;
}

.gameOtherSelect .muiltr-q34msv-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    min-height: 0px;
}

.play-window {
    position: relative;
}

.play-window-active {
    width: 1480px;
    height: 915px;
}

.play-iframe {
    width: 100%;
    height: 673px;
    background: rgba(0, 0, 0, .8);
}

.play-iframe-active {
    height: 848px;
}

.play-mask {
    width: 100%;
    height: 673px;
    background: rgba(0, 0, 0, .6);
    top: 0;
}

.play-mask-active {
    height: 848px;
}


.containerSpinBtn {
    width: 340px;
    height: 80px;
    margin: 0 auto;
    font-size: 48px;
}

.containerSpinBtn .btn {
    position: relative;
    top: 0;
    left: 0;
    width: 250px;
    height: 50px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

}

.containerSpinBtn .btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 30px;
    padding: 10px;
    letter-spacing: 1px;
    text-decoration: none !important;
    overflow: hidden;
    color: #fff;
    font-weight: 400px;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
}

.containerSpinBtn .btn:hover a {
    letter-spacing: 3px;
}

.spin-a-rata-disable:hover {
    letter-spacing: 1px !important;
}

.containerSpinBtn .btn .spin-a-rata-disable::before {
    content: none !important;
}

.containerSpinBtn .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
}

.containerSpinBtn .btn:hover a::before {
    transform: skewX(45deg) translate(200px);
}

.containerSpinBtn .btn-disable::before {
    content: none !important;
}

.containerSpinBtn .btn-disable::after {
    content: none !important;
}

.containerSpinBtn .btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: -5px;
    width: 30px;
    height: 10px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
}

.containerSpinBtn .btn:hover::before

/*lightup button*/
    {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
}


.containerSpinBtn .btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    top: -5px;
    width: 30px;
    height: 10px;
    background: #f00;
    border-radius: 10px;
    transition: 0.5s;
    transition-delay: 0.5;
}

.containerSpinBtn .btn:hover::after

/*lightup button*/
    {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
}

.containerSpinBtn .btn:nth-child(1)::before,
/*chnage 1*/
.containerSpinBtn .btn:nth-child(1)::after {
    background: #14C2A3;
    box-shadow: 0 0 5px #14C2A3, 0 0 15px #14C2A3, 0 0 30px #14C2A3, 0 0 60px #14C2A3;
}

.toolBarHead {
    padding: 0px 16px 0px 24px;
}

.gameListPl {
    padding-left: 0px;
}

.phoneGameListMg2 {
    width: 153px;
    height: 153px;
    margin: 8px 8px 8px 9px;
}

.messageBgColor {
    background-color: #1E2633;
    color: #ffffff;
    min-width: 290px;
}

.paiZhaoGC {
    background-image: url("../../public/assets/images/nav/paiZhaoGC.jpg");
    width: 535px;
    height: 761px;
}


.paiZhaoPG {
    background-image: url("../../public/assets/images/nav/paiZhaoPG.jpg");
    width: 452px;
    height: 707px;
}


.closeBtnPaiZhao {
    margin-top: 0px;
    z-index: 999;
    border-radius: 9999px;
    background-color: #1E2633;
    padding: 2px;
}


.closeBtnPaiZhao1 {
    margin-top: 10px;
    margin-right: 5px;
    z-index: 999;
    border-radius: 9999px;
    background-color: #1E2633;
    padding: 2px;
}

.courseBg {
    background-image: url("../../public/assets/images/nav/courseBG1.jpg");
    width: 1196px;
    height: 200px;
    font-size: 48px;
    padding-right: 100px;
}

.courseTxt1 {
    font-size: 34px;
}

.courseTxtYuanDian {
    width: 38px;
    height: 38px;
    text-align: center;
    background-color: #14C2A3;
    font-size: 28px;
    margin-top: 4px;
    margin-right: 10px;
}

.cookMaxWh {
    width: 608px;
    height: 41px;
    overflow: hidden;
}

.subscriptionDiv .muiltr-4pw36t-MuiButtonBase-root-MuiButton-root {
    margin-top: 6px;
    width: 90px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
}

.cookiesBtn {
    width: 90px;
    overflow: hidden;
}

.carousel .control-dots {
    margin: 0px 0px 4px 0px !important;
}

.carousel .slide .legend {
    background: none !important;
    opacity: 1 !important;
    width: 490px !important;
    height: 256px !important;
    padding: 0px !important;
    overflow: hidden;
    bottom: 180px !important;
    left: 3% !important;
    margin-left: 0% !important;
}

.lunBoTuWh {
    width: 1196px;
    height: 470px;
}

.lunboTxtLeft {
    text-align: left;
}

.lunboTxtBox {
    width: 590px;
    height: 180px;
    overflow: hidden;
}

.lunboTitleTxt2 {
    font-size: 38px;
    width: 460px;
}

.lunboTitleTxt3 {
    font-size: 22px;
    width: 370px;
}

.lunboTitleTxt4 {
    font-size: 32px;
    line-height: 36px;
    width: 430px;
}

.subscriptMg {
    margin-top: 16px;
    margin-left: 24px;
}

@media screen and (min-width: 0px) and (max-width: 750px) {
    .RightLayout1Chat {
        width: 0;
        min-width: 0;
    }

    .muiltr-w2sqq1>.panel {
        width: 100% !important;
    }

    .activitybanner {
        width: 100%;
        min-width: 28rem;
    }

    .height-470-zuQiu {
        margin-top: 10px;
        height: 280px;
    }

    .sigin-txt {
        font-size: 2rem;
        text-align: center;
        height: 2.8rem;
    }

    .sigin-txt1 {
        font-size: 3rem;
        text-align: center;
        height: 2.8rem;
    }

    .siginTxt {
        margin-top: 16.4rem;
        text-align: center;
    }

    .displayPhoneSwitch {
        display: block;
    }


    .displayPhoneSwitch1 {
        display: none;
    }

    .displayPhoneSwitch2 {
        display: none;
    }

    .displayPhoneSwitch3 {
        display: flex;
    }

    .sportPhoneWidth {
        width: 100%;
    }

    .phoneMx-16 {
        margin: 0px 0px;
    }

    .signBtnStyle {
        padding: 0px 0px;
        margin-top: 12px;
    }

    .zuQiu-img {
        content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu_1.png");
    }

    .zuQiu-img1 {
        content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu1_1.png");
    }

    .zuQiu-img2 {
        content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu2_1.png");
    }

    .zuQiu-img3 {
        content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu3_1.png");
    }

    .zuQiu-img4 {
        content: url("https://scource-static.funibet.com/funibet/images/index/zuQiu4_1.png");
    }

    .imgBigGameWh {
        width: 100%;
    }

    .payDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/payDi_1.png");
        background-repeat: no-repeat;
    }

    .payImg {
        content: url("https://scource-static.funibet.com/funibet/images/index/pay_1.png");
        width: 100%;
    }

    .crypto-txt {
        text-align: center;
        width: 100%;
        font-size: 26px;
    }

    .crypto {
        width: 100%;
    }

    .payPT {
        padding-top: 0px;
    }

    .phoneTop {
        width: 140px;
        margin-top: 0px;
        margin-bottom: 4px;
        font-size: 16px;
        padding: 0px 0px 0px 0px;
    }

    .buyCryP-20 {
        padding: 10px 10px 16px 10px;
    }

    .tabCell .muiltr-q9ghjk-MuiTableCell-root {
        display: table-cell;
    }

    .phonefootTop {
        margin-top: 20px;
    }

    .menuText {
        color: #B8BABF;
    }

    .backGround-footImg {
        background-image: none;
        background-repeat: no-repeat;
    }

    .loginHead {
        width: 38px;
        height: 38px;
    }

    .phoneGameSearch {
        display: block;
    }

    .maxWidth {
        min-width: 100px;
        width: 30.5%;
        height: auto;
        aspect-ratio: 1 / 1;
        /* padding-bottom: 30.5%; */
    }

    .phoneGameListMg2 {
        width: 30.5%;
        height: auto;
        margin: 1% 1.36% 3% 1.36%;
    }

    .imgGameWh {
        width: 100%;
        aspect-ratio: 1 / 1;
    }

    .marginXY {
        margin: 20px 4px 0px 4px;
    }

    .recommendWidth {
        max-width: 100%;
    }

    .phoneMW-224 {
        min-width: 156px;
        width: 46%;
    }

    .phoneMW-222 {
        min-width: 142px;
        width: 46%;
    }

    .phone100Bai {
        width: 100%;
        min-width: 100%;
    }

    .widthPhone-164 {
        min-width: 100px;
        width: 30%;
    }

    .workSty {
        width: 118px;
        min-width: 118px;
        height: auto;
    }

    .checkInDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/phoneQianDao.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 96%;
        height: 582px;
    }

    .checkInTitleMt {
        padding-top: 5px;
        font-size: 20px;
    }

    .closeBtnCheckIn {
        margin-top: 9%;
    }

    .checkInTxtmtMiaoShu {
        font-size: 15px;
        padding-top: 7%;
        width: 96%;
        margin: 0px auto;
        color: #AF57E8;
    }

    .checkInPcDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/phoneQianDao1.png");
        background-repeat: no-repeat;
    }

    .shareDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/shareDi.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        max-width: 360px;
        width: 360px;
        height: 239px;
    }

    .btnShare1 {
        top: 40px;
        right: 0px;
        bottom: 0px;
        left: 105px;
    }

    .btnShare2 {
        top: 105px;
        right: 0px;
        bottom: 0px;
        left: 155px;
    }

    .btnShare3 {
        top: 40px;
        right: 0px;
        bottom: 0px;
        left: 220px;
    }

    .btnShare4 {
        top: 105px;
        right: 0px;
        bottom: 0px;
        left: 288px;
    }

    .btnShare1Img {
        width: 53px;
        height: 53px;
    }

    .btnShare5 {
        top: 208px;
        right: 0px;
        bottom: 0px;
        left: 48px;
        color: #ffffff;
        font-size: 14px;
    }

    .btnShareTitle {
        font-size: 22px;
        width: 53px;
        text-align: center;
        color: #ffc600;
    }


    .shareBannerTitle {
        font-size: 22px;
    }

    .fundBgDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/bigXcDi.png");
        background-size: 100% 100%;
        padding: 10px 10px 0px 10px;
        width: 100%;
        height: 140px;
    }

    .FundText-28 {
        font-size: 16px;
    }

    .FundWidth-480 {
        width: 60%;
        height: 70px;
    }

    .fundBuyBtn {
        margin-top: 4px;
        font-size: 18px;
        height: 40px;
        line-height: 40px;
        width: 150px;
    }

    .fundTitle0 {
        margin-top: 24px;
    }

    .fundTitle1 {
        padding-top: 18px;
    }

    .fundTitle2 {
        margin-left: 10px;
        width: 30%;
        text-align: left;
    }

    .fundTitle3 {
        width: 24%;
        text-align: left;
    }

    .fundTitle4 {
        width: 22%;
        text-align: left;
        margin-right: 20px;
    }

    .fundLineBtn {
        height: 64px;
        margin: 10px auto;
        padding: 0px 0px;
        width: 94%;
    }

    .Fundwidth-480Icon {
        width: 54%;
    }

    .Fundml-20 {
        margin-left: 4px;
    }

    .Fundwh42 {
        width: 36px;
        height: 36px;
    }

    .fundTxtIcon {
        font-size: 12px;
    }

    .Fundwidth-120Icon {
        width: 16%;
        height: 70px;
        line-height: 70px;
        margin-left: 19%;
    }

    .fundBtnRev {
        width: 120px;
        height: 40px;
        line-height: 40px;
        margin: auto 0;
        margin-left: 0px;
        font-size: 14px;
    }

    .fundBtnRev1 {
        width: 120px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        margin: auto 0;
        margin-left: 0px;
    }

    .fundJia {
        width: 16px;
        height: 16px;
        margin: 18px 0px 0px 0px;
    }

    .vipBgDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/vipDi.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        padding: 0px 0px 0px 0px;
        height: 170px;
    }

    .vipZi {
        font-size: 44px;
        margin-left: 40%;
    }

    .vipZi2 {
        font-size: 18px;
        margin-left: 40%;
    }

    .vipZi3 {
        width: 100%;
        height: 90px;
        padding: 10px 10px 0px 10px;
    }

    .vipZi4 {
        width: 100%;
        height: 22px;
        font-size: 14px;
        margin-top: 15px;
        line-height: 22px;
    }

    .vipwidth-80 {
        width: 40px;
    }

    .viptext-20 {
        margin-top: 4px;
        font-size: 14px;
    }

    .vipZi5 {
        font-size: 14px;
        margin-top: 44px;
    }

    .vipZi6 {
        font-size: 22px;
        margin-top: 20px;
    }

    .vipZi7 {
        margin-top: 10px;
        font-size: 16px;
        padding: 0px 10px;
    }

    .vipZi8 {
        font-size: 16px;
        margin-left: 15%;
        padding-top: 10px;
    }

    .vipZi9 {
        font-size: 14px;
        line-height: 16px;
        margin-left: 22%;
        width: 74%;
    }

    .vipZi10 {
        font-size: 14px;
        line-height: 16px;
        margin-top: 4px;
        margin-left: 22%;
        width: 74%;
    }

    .vipZi11 {
        font-size: 14px;
        line-height: 16px;
        margin-top: 0px;
        margin-left: 22%;
        width: 74%;
    }

    .vipZi12 {
        font-size: 14px;
        line-height: 24px;
        margin-left: 12px;
    }

    .vipZi13 {
        font-size: 18px;
        margin-top: 10px;
        margin-left: 32%;
        width: 180px;
        height: 40px;
    }

    .vipLvDiImg {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/vipLvDi.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 424px;
        padding: 0px 20px;
    }

    .vipLvDiImg2 {
        background-image: url("https://scource-static.funibet.com/funibet/images/index/vipDi4.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 300px;
        padding-top: 0px;
    }

    .vipPhoneClue {
        background-image: url("../../public/assets/images/nav/vipPhoneDi.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 44px;
        margin-top: 8px;
    }

    .depositDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/nav/czlb1.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 100%;
        height: 460px;
        max-width: 360px;
    }

    .giftDi {
        background-image: url("../../public/assets/images/nav/diPan4.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        max-width: 96%;
        height: auto;
        min-height: 246px;
    }

    .giftTxtmt-32 {
        font-size: 22px;
        margin-top: 5%;
        color: #ffffff;
    }

    .giftTxtmtInput {
        margin-top: 4%;
    }

    .closeBtnGift {
        margin-top: 4.2rem;
    }

    .giftBtnmt-48 {
        margin-top: 5%;
    }

    .giftDiBtn {
        width: 140px;
        height: 42px;
        line-height: 42px;
        font-size: 20px;
        margin-top: 0%;
        color: #ffffff;
    }

    .giftTitleMt {
        padding-top: 6px;
        font-size: 28px;
    }

    .spinDi {
        background-image: url("https://scource-static.funibet.com/funibet/images/nav/diPan3.jpg");
        max-width: 360px;
        width: 96%;
        min-width: 360px;
        height: 660px;
        border-radius: 15px;
    }

    .spinDi1 {
        background-image: url("https://scource-static.funibet.com/funibet/images/nav/diPan1.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 100%;
        height: 100%;
        max-width: 308px;
        max-height: 308px;
    }

    .spinDi2 {
        background-image: url("https://scource-static.funibet.com/funibet/images/nav/diPan2.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        width: 100%;
        height: 100%;
        max-width: 354px;
        max-height: 352px;
    }

    .spinDi8 {
        max-width: 360px;
        width: 360px;
    }


    .money-height-36 {
        width: 9%;
        height: 9%;
        object-fit: contain;
    }

    .money-text-20 {
        font-size: 1.8rem;
    }

    .money-width-140 {
        width: 100%;
    }

    .spinTxt {
        margin-top: 6px;
        font-size: 48px;
        height: 48px;
        line-height: 48px;
    }

    .phoneMove {
        margin-left: 100%;
        animation: testMove 0.4s ease-in-out;
        animation-fill-mode: forwards;
    }


    @keyframes testMove {
        from {
            margin-left: 100%;
        }

        to {
            margin-left: 0%;
        }
    }

    .phoneMove2 {
        margin-left: -100%;
        animation: testMove2 0.4s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes testMove2 {
        from {
            margin-left: 0%;
        }

        to {
            margin-left: 100%;
        }
    }

    .maoBoLi {
        background-color: rgba(30, 41, 59, 0.6);
        backdrop-filter: blur(6px);
    }

    .walletMl {
        margin-left: 0px;
        margin: 0 auto;
    }

    .copyMl {
        margin-left: 0px;
        margin: 0 auto;
    }

    .loginInBtn {
        font-size: 16px;
    }

    .widthPhone400 {
        width: 90%;
    }

    .phoneMove3 {
        bottom: 2%;
        animation: testMove3 0.4s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes testMove3 {
        from {
            bottom: 2%;
        }

        to {
            bottom: -35%;
        }
    }

    .phoneHeadBtn {
        padding: 0px 0px 0px 0px;
        min-width: 26px;

    }

    .phoneGuoQiBtn {
        min-width: 24px;
        padding: 0px 0px 0px 0px;
        margin-right: 6px;
    }

    .phoneComTop {
        width: 360px;
        margin-top: 60px;
    }

    .phoneContMT {
        /*margin-top: 4.8rem;*/
        margin-bottom: 5.2rem;
    }

    .affiliateMg {
        margin-top: 0px;
        margin-right: 0px;
        margin: 0 auto;
        padding-top: 0px;
        padding-bottom: 0px;
        width: 340px;
        height: 180px;
        border-radius: 5px;
    }

    .phoneAffp-12 {
        padding-top: 0px;
    }

    .phoneTxtAff {
        font-size: 20px;
        margin-top: 8px;
    }

    .phoneTxtAff2 {
        padding-top: 0px;
        font-size: 14px;
    }

    .phoneTxtAff3 {
        margin-top: 4px;
        margin-bottom: 4px;
        margin-left: 44px;
        font-size: 16px;
    }

    .width-280 {
        width: 320px;
    }

    .phoneTxtAff4 {
        height: 36px;
        line-height: 36px;
    }

    .phoneTxtAff5 {
        margin-top: 6px;
    }

    .phoneTxtAff6 {
        height: 52px;
    }

    .phoneTxtAff7 {
        padding: 0px 0px 0px 0px;
        width: 100%;
        border: none;
        margin-top: 16px;
    }

    .phoneAffmt-8 {
        margin-top: 0px;
    }

    .phoneTxtAff8 {
        padding: 4px 0px 0px 0px;
        width: 90%;
        height: 74px;
    }

    .phoneTxtAff9 {
        margin-top: 6px;
    }

    .phoneTxtAff10 {
        margin-top: 8px;
    }

    .phoneTxtAff99 {
        width: 44%;
        margin-top: 16px;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .pcMgH {
        padding: 0px 0px 0px 0px;
    }

    .phoneMgl {
        margin-left: 16px;
    }

    .phoneJust {
        justify-content: space-between;
    }

    .phoneTxtAff12 {
        width: 90%;
        margin: 0 auto;
    }

    .phoneTxtAff13 {
        width: 90%;
        height: 50px;
    }

    .phoneTxtAff14 {
        margin-top: 20px;
        height: 244px;
    }

    .affiliateBg {
        background-image: url("../../public/assets/images/affiliate/xcDi2.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .affiliateBackGroundColor {
        background-color: #0E1421;
    }

    .affiliateBg2 {
        background-image: none;
        background-repeat: no-repeat;
    }

    .affilamt-40 {
        margin-top: 20px;
    }

    .phoneAffmt-16 {
        margin-top: 10px;
        font-size: 20px;
    }

    .ctyptoBtn-txt {
        text-align: center;
        line-height: 50px;
    }

    .phoneTxtAff15 {
        padding-top: 14px;
        font-size: 18px;
        margin-bottom: 0px;
    }

    .phoneTxtmx-120 {
        margin: 0px 0px;
    }

    .phoneTxtAff16 {
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 32px;
        height: 52px;
    }

    .phoneTxtAff17 {
        width: 240px;
        font-size: 14px;
        margin-top: 4px;
        margin-bottom: 8px;
    }

    .phoneTxtAff18 {
        width: 100%;
        font-size: 28px;
        padding-top: 6px;
        min-width: 200px;
    }

    .affiliateLaba {
        margin-left: 0px;
        margin: 0 auto;
        font-size: 13px;
        text-align: 13px;
        padding-top: 10px;
    }

    .width-80bai {
        width: 90%;
    }

    .affiliateBg3 {
        background-image: none;
        background-color: #1E2633;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 20px;
        height: 134px;
    }

    .cookiesSty {
        width: 98%;
        height: auto;
        color: #ffffff;
        background-color: #1E2633;
        border-radius: 10px;
        box-shadow: 3px 3px 3px #000000;
    }

    .cookiesPhoneTxt {
        width: 100%;
        text-align: center;
    }

    .cookies-list1 {
        display: block;
        width: 100%;
    }

    .cookiesTxt1 {
        width: 100%;
    }

    .cookiesBtn {
        margin-top: 12px;
        width: 40%;
        margin-left: 30%;
    }

    .gameTipsBtn {
        margin-top: 12px;
        width: 30%;
    }

    .gameMt1 {
        margin-top: 12px;
    }

    .gameMt2 {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .muiltr-b6ns52-MuiPaper-root {
        border-radius: 0px;
    }

    .muiltr-sg8esm-MuiPaper-root {
        border-radius: 0px;
    }

    .phoneBorderChat {
        border-radius: 15px;
    }

    .phoneChatPl {
        padding-left: 0px;
    }

    .toolBarHead {
        padding: 0px 0px;
    }

    /* #root {
        height: 100%;
        overflow: hidden;
    } */
    .phoneDaoHangNoClick {
        width: 13%;
        height: 26px;
        text-align: center;
        color: #FFFFFF;
        transition: all 0.4s;
    }

    .phoneDaoHangClick {
        width: 35%;
        height: 26px;
        text-align: center;
        color: #14C2A3;
        transition: all 0.4s;
    }

    .phoneDaoHangTxtMove {
        margin-top: 26px;
        animation: phoneDaoHangMove1 0.3s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes phoneDaoHangMove1 {
        from {
            margin-top: 26px;
        }

        to {
            margin-top: 0px;
        }
    }

    .phoneDaoHangTxtMove2 {
        margin-top: 0px;
        color: #14C2A3;
        overflow: hidden;
        animation: phoneDaoHangMove2 0.3s ease-in-out;
        animation-fill-mode: forwards;
    }

    @keyframes phoneDaoHangMove2 {
        from {
            margin-top: 0px;
        }

        to {
            margin-top: 26px;
        }
    }

    .phoneDaoHangXianMove {
        width: 0%;
        animation: phoneDaoHangXianMove 0.3s ease-in-out;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
    }

    @keyframes phoneDaoHangXianMove {
        from {
            width: 0%;
        }

        to {
            width: 100%;
        }
    }


    .phoneDaoHangXianMove2 {
        width: 100%;
        animation: phoneDaoHangXianMove2 0.3s ease-in-out;
        animation-delay: 0.3s;
        animation-fill-mode: forwards;
    }

    @keyframes phoneDaoHangXianMove2 {
        from {
            width: 100%;
        }

        to {
            width: 0%;
        }
    }

    .colorGameListTitle {
        color: #B9BBC0;
    }

    .phoneDaoHangIconMove {
        transform: scaley(1);
        animation: phoneDaoHangIconMove 0.3s linear;
        animation-fill-mode: forwards;
    }

    @keyframes phoneDaoHangIconMove {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    .phone404Top {
        margin-top: 25vh;
    }

    .gameListPl {
        padding-left: 2%;
    }

    .courseTxt1 {
        font-size: 16px;
    }

    .courseTxtYuanDian {
        width: 24px;
        min-width: 24px;
        height: 24px;
        text-align: center;
        background-color: #14C2A3;
        font-size: 18px;
        margin-top: 0px;
        margin-right: 10px;
    }

    .courseBg {
        background-image: url("../../public/assets/images/nav/courseBGPhone1.jpg");
        width: 360px;
        height: 164px;
        font-size: 16px;
        padding-right: 0px;
    }

    .cookMaxWh {
        width: 97%;
        height: auto;
    }

    .carousel .slide .legend {
        background: none !important;
        opacity: 1 !important;
        width: 340px !important;
        height: 140px !important;
        padding: 0px !important;
        overflow: hidden;
        bottom: 0px !important;
        left: 0% !important;
        right: 0% !important;
        margin: 0% auto !important;
    }

    .lunboTxtBox {
        margin-top: 40px;
        width: 340px;
        height: 80px;
        overflow: hidden;
    }

    .lunboTxtLeft {
        text-align: center;
    }

    .lunBoTuWh {
        width: 100%;
        height: auto;
    }

    .carousel .control-dots {
        margin: 0px 0px 10px 0px !important;
    }

    .lunboTitleTxt2 {
        font-size: 20px;
        width: 340px;
    }

    .lunboTitleTxt3 {
        font-size: 16px;
        line-height: 18px;
        width: 340px;
    }

    .lunboTitleTxt4 {
        font-size: 24px;
        line-height: 24px;
        width: 340px;
    }

    .subscriptionSty {
        width: 360px;
        height: 134px;
        background-color: #1E2633;
        border-radius: 10px;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, .6);
    }

    .downAppSty {
        width: 100%;
        height: 100px;
        background-color: #1E2633;
        box-shadow: 3px -3px 3px rgba(0, 0, 0, .5);
        border-radius: 0px;
    }

    .downAppSty2 {
        position: fixed;
        bottom: 0%;
        left: 0%;
        right: 0%;
        z-index: 999;
        margin: 0 auto;
    }

    .subscriptMg {
        margin-top: 10px;
        margin-left: 10px;
    }

    .subscriptionTxt1 {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        width: 260px;
        height: 48px;
        overflow: hidden;
    }

    .downPhoneCloseBtn {
        width: 24px;
        height: 24px;
        margin: 22px 12px 0px 12px;
    }

    .downPhoneAppTxt1 {
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        width: 260px;
        height: 66px;
        overflow: hidden;
    }

    .subscriptionMg2 {
        margin-left: 10px;
        width: 340px;
    }

    .phoneSubscriptionMg {
        justify-content: space-between;
        padding: 0px 60px;
    }

    .activePopupSty {
        width: 360px;
        height: 200px;
        background-color: #1E2633;
        border-radius: 10px;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, .6);
        position: fixed;
        bottom: 40%;
        right: 0;
        left: 0;
        margin: 0 auto;
        transition: all 0.3s;
    }

    .activeImageDi {
        width: 128px;
        height: 128px;
        margin-left: 10px;
    }

    .activePopupDi {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
    }

    .activeTxt2 {
        width: 200px;
        height: 80px;
        overflow: hidden;
        color: #ffffff;
    }

    .activeTxt3 {
        width: 200px;
        margin-right: 10px;
    }

    .activeBtn {
        width: 170px;
        overflow: hidden;
        margin-top: 10px;
    }

    @keyframes testMove5 {
        from {
            right: 0%;
        }

        to {
            right: -200%;
        }
    }

    .phoneLoadHeight {
        height: 100vh;
    }

    .vipJdWidth {
        width: 70%;
    }

    .vipJdTiao {
        height: 20px;
        margin-top: 14px;
    }

    .vipJdTiao1 {
        margin-top: 14px;
    }

    .vipJdTiao2 {
        left: 0;
        right: 0;
        margin: auto;
        margin-top: 13px;
    }

    .promotion-list3 {
        display: flex;
        /* align-items: center; */
        justify-content: flex-start;
        padding-top: 20px;
        width: 100%;
    }

    .fixedPhone {
        position: fixed;
        transform: translate3d(0, -1px, 0);
    }

    .huoDongDing {
        padding: 36px 10px 10px 10px;
    }

    .affHuoDongDing {
        margin-top: 26px;
        padding-top: 10px;
    }

    .gameHuoDongDing {
        margin-top: 26px;
    }

    .redPointSty {
        left: 42px;
        top: 40px;
    }

    .receivedSty {
        left: 34px;
        top: 30px;
    }

    .chatMr-6 {
        margin-right: 0px;
    }

    .gameTipsTxt1 {
        width: 100%;
    }

    .sportTipsSty {
        position: fixed;
        bottom: 36%;
        margin: 0px auto;
        left: 0%;
        right: 0%;
        z-index: 999;
    }

    .workSty1 {
        width: 32%;
        height: auto;
        aspect-ratio: 153 / 60;
        margin-right: 2%;
    }

    .imgGameIconWh {
        width: 40%;
        aspect-ratio: 1 / 1;
    }

    .bannerHeightBtn {
        height: 200px;
    }
}
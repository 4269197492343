.logo {
    animation: fuse 3s infinite ease-in-out forwards;
    opacity: 1;
}

@keyframes fuse {
    0% {
        opacity: 0.52;
    }

    12% {
        opacity: 0.64;
    }

    25% {
        opacity: 0.76;
    }

    37% {
        opacity: 0.88;
    }

    50% {
        opacity: 1;
    }

    62% {
        opacity: 0.88;
    }

    74% {
        opacity: 0.76;
    }

    86% {
        opacity: 0.64;
    }

    100% {
        opacity: 0.52;
    }
}